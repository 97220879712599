import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchSelect from '../Global/SearchSelect';
import Spinner from '../Global/Spinner';
import './scss/MoveCard.scss';
import { fetchBoards, fetchBoardLists } from '../../redux/Slices/boardsSlice';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';
import { config } from '../../config';

const MoveCard = () => {
    const dispatch = useDispatch();
    const boardId = useSelector(selectBoardId);
    const { boards, actionLists } = useSelector(state => state.boards);
    const { card } = useSelector(state => state.card);

    

    const [moveState, setMoveState] = useState({
        selectedBoard: boardId || null,
        selectedList: card.actionList._id || null,
        selectedPosition: card.shortId || null,
        targetPosition: null,
        loading: false,
    });

    useEffect(() => {
        dispatch(fetchBoards());
    }, [dispatch]);

    useEffect(() => {
        if (moveState.selectedBoard && !actionLists[moveState.selectedBoard]) {
            dispatch(fetchBoardLists(moveState.selectedBoard));
        }
    }, [moveState.selectedBoard, actionLists, dispatch]);

    useEffect(() => {
        if (moveState.selectedBoard && moveState.selectedBoard !== boardId) {
            const lists = actionLists[moveState.selectedBoard] || [];
            const firstList = lists.length > 0 ? lists[0] : null;

            setMoveState(prev => ({
                ...prev,
                selectedList: firstList?._id || null,
                selectedPosition: firstList?.cards?.length > 0 ? firstList.cards[firstList.cards.length - 1].shortId : 1,
            }));

        }
    }, [moveState.selectedBoard, actionLists, boardId]);

    const boardOptions = useMemo(() => {
        return boards.map(board => ({
            value: board.shortId,
            label: `${board.title}${board.shortId === boardId ? '<br><span className="current-option">(current)</span>' : ''}`,
            workspaceShortId: board.workspaceShortId,
            workspaceName: board.workspaceName,
        })) || [];
    }, [boards, boardId]);

    const actionListOptions = useMemo(() => {
        const lists = actionLists[moveState.selectedBoard] || [];
        return lists.map(actionList => ({
            value: actionList._id.toString(),
            label: `${actionList.title}${actionList._id.toString() === card.actionList._id ? '<br><span className="current-option">(current)</span>' : ''}`,
        }));
    }, [actionLists, moveState.selectedBoard, card.actionList._id]);

    const positionOptions = useMemo(() => {
        const lists = actionLists[moveState.selectedBoard] || [];
        const selectedActionList = lists.find(actionList => actionList._id === moveState.selectedList);

        return selectedActionList?.cards.map((indexCard, index) => ({
            value: indexCard.shortId,
            label: `${index + 1} ${indexCard.shortId === card.shortId ? '<br><span className="current-option">(current)</span>' : ''}`,
            index,
        })) || [];
    }, [actionLists, moveState.selectedBoard, moveState.selectedList, card.shortId]);

    const handleBoardSelect = useCallback((selectedBoardOption) => {
        setMoveState(prev => ({
            ...prev,
            selectedBoard: selectedBoardOption.value,
            selectedList: null,
            selectedPosition: null,
        }));
    }, []);

    const handleActionListSelect = useCallback((selectedListOption) => {
        const lists = actionLists[moveState.selectedBoard] || [];
        const actionList = lists.find(actionList => actionList._id === selectedListOption.value);
        setMoveState(prev => ({
            ...prev,
            selectedList: selectedListOption.value,
            selectedPosition: actionList?.cards?.length > 0 ? actionList.cards[actionList.cards.length - 1].shortId : card.shortId,
        }));
    }, [actionLists, moveState.selectedBoard, card.shortId]);

    const handlePositionSelect = useCallback((selectedPosition) => {
        setMoveState(prev => ({
            ...prev,
            selectedPosition: selectedPosition.value,
            targetPosition: selectedPosition.index,
        }));
    }, []);

    const handleMoveCard = useCallback(async () => {
        setMoveState(prev => ({ ...prev, loading: true }));
        const token = localStorage.getItem('accessToken');
        try {
            const targetListId = moveState.selectedList;
            const targetPosition = moveState.selectedList === card.actionList._id
                ? moveState.targetPosition
                : (actionLists[targetListId]?.cards.length || 0);

            await fetch(`${config.API_URI}/api/boards/${boardId}/cards/order`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    cardId: card._id,
                    targetListId,
                    targetPosition,
                }),
            });
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
        } catch (error) {
            console.error('Error moving card:', error);
        } finally {
            setMoveState(prev => ({ ...prev, loading: false }));
        }
    }, [boardId, card._id, moveState.selectedList, moveState.targetPosition, actionLists, card.actionList._id, dispatch]);

    return (
        <div className="move-card">
            <h2>Move Card</h2>
            <div className="group">
                <h3>Select Destination</h3>
                <SearchSelect
                    options={boardOptions}
                    onSelect={handleBoardSelect}
                    placeholder="Select a board"
                    isGrouped={true}
                    defaultValue={moveState.selectedBoard}
                />
            </div>
            <div className='actionlists-group'>
                <div className="group actionlists">
                    <h3>List</h3>
                    <SearchSelect
                        options={actionListOptions}
                        onSelect={handleActionListSelect}
                        placeholder="Select a list"
                        isGrouped={false}
                        defaultValue={moveState.selectedList}
                    />
                </div>
                <div className="group position">
                    <h3>Position</h3>
                    <SearchSelect
                        options={positionOptions}
                        onSelect={handlePositionSelect}
                        placeholder="Select a position"
                        isGrouped={false}
                        defaultValue={moveState.selectedPosition}
                    />
                </div>
            </div>
            <button onClick={handleMoveCard} disabled={moveState.loading}>
                {moveState.loading ? (
                    <Spinner size={20} color="#ffffff" speed={1.5} strokeWidth={5} />
                ) : 'Move'}
            </button>
        </div>
    );
};

export default MoveCard;
