import React, { useState, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Board from './Board';
import CardDetails from './CardDetails';

import './scss/index.scss';

function BoardWrapper() {
    const location = useLocation();
    const isCardPath = location.pathname.startsWith('/c/');

    const [boardState, setBoard] = useState({ actionLists: [] });
    const { board, isLoading } = useSelector((state) => state.board);

    return (
        <div
            id="zoobbe-board"
            style={{
                backgroundImage: isLoading || !board?.cover?.url ? 'none' : `url(${board.cover.url})`,
            }}
        >
            <Board board={boardState} setBoard={setBoard} />
            {isCardPath && <CardDetails board={boardState} setBoard={setBoard} />}
        </div>
    );
}

export default BoardWrapper;
