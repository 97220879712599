import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from '../../redux/Slices/toastSlice';
import { config } from '../../config';

const AcceptInvitationRequest = () => {
    const { boardId, shortId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [accepting, setAccepting] = useState(false);

    // Extract token from query parameters
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const invitationType = boardId ? 'board' : 'workspace';
    const id = boardId || shortId; // Either boardId or workspace shortId

    useEffect(() => {
        if (!token) {
            dispatch(showToast({ message: 'Invalid link: Missing token', type: 'error' }));
            navigate('/');
            return;
        }

        const acceptInvitation = async () => {
            setAccepting(true);
            try {
                const endpoint =
                    invitationType === 'board'
                        ? `${config.API_URI}/api/boards/accept`
                        : `${config.API_URI}/api/workspace/accept`;

                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id, token }),
                });

                const data = await response.json();


                if (response.ok) {
                    // Save the access token
                    localStorage.setItem('accessToken', data.accessToken);

                    dispatch(
                        showToast({
                            message: `Successfully joined the ${invitationType}`,
                            type: 'success',
                        })
                    );

                    setTimeout(() => {
                        if (!data.isDefaultPasswordSet) {
                            // Redirect to set password page
                            window.location.href = '/set-password';
                        } else {
                            // Redirect to the board or workspace
                            const redirectPath =
                                invitationType === 'board' ? `/b/${id}` : `/w/${id}/boards`;
                            window.location.href = redirectPath;
                        }
                    }, 1000);
                } else {
                    dispatch(
                        showToast({
                            message: data.message || `Failed to join ${invitationType}`,
                            type: 'error',
                        })
                    );
                }
            } catch (error) {
                dispatch(
                    showToast({
                        message: `Error accepting ${invitationType}`,
                        type: 'error',
                    })
                );
            } finally {
                setAccepting(false);
            }
        };

        acceptInvitation();
    }, [token, id, invitationType, dispatch, navigate]);

    if (accepting) {
        return <div>Joining {invitationType}...</div>;
    }

    return null;
};

export default AcceptInvitationRequest;
