// utils/helpers.js

import { fetchWorkspaces } from '../redux/Slices/workspaceSlice';
import { config } from '../config';

const shortUUID = require('short-uuid');

// utils/helpers.js
export const getUser = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
        return false;
    }

    try {
        const response = await fetch(config.API_URI + '/api/users/me', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Invalid token');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error validating token:', error);
        return false;
    }
};

export const getUserById = async (userId) => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
        return false;
    }

    try {
        const response = await fetch(config.API_URI + `/api/users/${userId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Invalid token');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error validating token:', error);
        return false;
    }
};
export const getUsers = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
        return false;
    }

    try {
        const response = await fetch(config.API_URI + '/api/users/all-users', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Invalid token');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error validating token:', error);
        return false;
    }
};


// Function to refresh the access token
export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        return false;
    }

    try {
        const response = await fetch(config.API_URI + '/api/users/refresh-token', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: refreshToken }),
        });

        const data = await response.json();
        if (data.accessToken) {
            localStorage.setItem('accessToken', data.accessToken);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        return false;
    }
};


export const toSlug = (title) => {
    const slug = title?.toLowerCase().replace(/\W+/g, '-');

    return slug?.replace(/^-+/, '').replace(/-+$/, '');
}


const translator = shortUUID();

export const maskId = (id) => {
    return translator.fromUUID(id);
};

export const unmaskId = (maskedId) => {
    return translator.toUUID(maskedId);
};


export const sanitizeHtml = (html) => {
    // Regex to match <pre> tags and their content
    const preRegex = /<pre.*?>([\s\S]*?)<\/pre>/gi;

    // Replace content within <pre> tags
    let sanitizedHtml = html.replace(preRegex, (match, content) => {
        const cleanedContent = content.replace(/\s+/g, ' ');
        return `<pre>${cleanedContent}</pre>`;
    });

    // Strip out all HTML tags, <br> tags, and whitespace
    const strippedContent = sanitizedHtml.replace(/<\/?[^>]+(>|$)|\s|<br\s*\/?>/gi, '');

    // If the stripped content is empty, return an empty string; otherwise, return the sanitized HTML
    return strippedContent === '' ? '' : sanitizedHtml;
};


export const addMember = async ({ cardId, memberId }) => {
    const token = localStorage.getItem('accessToken');
    try {
        const response = await fetch(config.API_URI + `/api/cards/${cardId}/addMember`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ memberId })
        });

        if (!response.ok) {
            throw new Error('Failed to add member');
        }

        const data = await response.json();

        return { cardId, member: data }
        // return data;
    } catch (error) {
        console.error('Error adding member:', error);
        throw error;
    }
};
export const uploadFile = async (event, cardId) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(config.API_URI + `/api/cards/attachments/${cardId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });

        if (!response.ok) {
            throw new Error('Failed to upload file');
        }

        const attachments = await response.json();
        return attachments;
    } catch (error) {
        console.error('Error uploading file:', error);
    }
};

export const timeAgo = (datetime) => {
    const now = new Date();
    const date = new Date(datetime);
    const diffInMs = now - date;

    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
        return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`;
    } else if (diffInMonths > 0) {
        return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`;
    } else if (diffInDays > 0) {
        return date.toDateString(); // Return actual date if more than a day
    } else if (diffInHours > 0) {
        return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
    } else if (diffInMinutes > 0) {
        return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds > 0) {
        return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
    } else {
        return 'just now';
    }
}

export const getFileExtension = (url) => {
    const regex = /\/[^\/?#]+\.([a-zA-Z0-9]+)(?:\?.*)?$/;
    const match = regex.exec(url);
    return match && match[1] ? match[1] : '';
};


export const getFilters = async ({ boardId }) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
        const response = await fetch(`${config.API_URI}/api/users/me/filters?boardId=${boardId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch filters');
        }

        const data = await response.json();

        return data;

    } catch (error) {
        console.error('Error fetching filters:', error);
    }
};

const lookupFunctions = {

    workspace: (data, { id, workspace, shortName }) => {


        if (id) {
            return data.find(ws => ws._id === id) || null;
        }
        if (workspace) {
            return data.find(ws => {
                console.log(ws.shortId);

                return ws.shortId === workspace;
            }) || null;
        }
        if (shortName) {
            return data.find(ws => ws.shortName === shortName.workspaceSlug) || null;
        }
        return null;
    },

    boards: (data, { workspace }) => {
        const ws = data.find(ws => ws._id === workspace);
        return ws ? ws.boards : [];
    },

    boardsByShortName: (data, { workspace }) => {
        const ws = data.find(ws => ws.shortName === workspace);
        return ws ? ws.boards : [];
    },
    boardsByShortId: (data, { workspace }) => {
        const ws = data.find(ws => ws.shortId === workspace);
        return ws ? ws.boards : [];
    },

    boardsByMember: (data, { memberId }) => {
        return data
            .flatMap(workspace => workspace.boards)
            .filter(board => board.members.some(member => member._id === memberId));
    },

    board: (data, { id }) => {
        const ws = data.find(ws => ws.boards.some(b => b._id === id));
        return ws ? ws.boards.find(b => b._id === id) : null;
    },

    boardByCard: (data, { cardId }) => {
        const boards = data
            .flatMap(workspace => workspace.boards)
            .filter(board => board.actionLists
                .some(actionList => actionList.cards
                    .some(card => card._id === cardId))
            );

        return boards.length > 0 ? boards[0] : null;
    },

    actionList: (data, { actionListId }) => {
        const actionLists = data
            .flatMap(workspace => workspace.boards)
            .flatMap(board => board.actionLists);

        return actionLists.find(al => al._id === actionListId) || null;
    },

    cards: (data, { workspace, board, actionlist }) => {
        if (workspace) {
            const ws = data.find(ws => ws._id === workspace);
            if (!ws) return [];
            return ws.boards.flatMap(b => b.actionLists.flatMap(al => al.cards));
        }
        if (board) {
            const ws = data.find(ws => ws.boards.some(b => b._id === board));
            return ws ? ws.boards.find(b => b._id === board).actionLists.flatMap(al => al.cards) : [];
        }
        if (actionlist) {
            const ws = data.find(ws => ws.boards.some(b => b.actionLists.some(al => al._id === actionlist)));
            return ws ? ws.boards.flatMap(b => b.actionLists.find(al => al._id === actionlist).cards) : [];
        }
        return [];
    },

    cardsByMember: (data, { memberId }) => {
        return data
            .flatMap(workspace => workspace.boards)
            .flatMap(board => board.actionLists)
            .flatMap(actionList => actionList.cards)
            .filter(card => card.users?.some(user => user._id === memberId));
    },

    card: (data, { id }) => {
        const ws = data.find(ws =>
            ws.boards.some(b => b.actionLists.some(al => al.cards.some(c => c._id === id)))
        );
        return ws ? ws.boards
            .flatMap(b => b.actionLists)
            .flatMap(al => al.cards)
            .find(c => c._id === id) : null;
    },

    members: (data, { workspace, board, shortName, card, cardId }) => {
        if (workspace) {
            const ws = data.find(ws => ws._id === workspace);
            return ws ? ws.members : [];
        }
        if (shortName) {
            const ws = data.find((ws) => ws.shortName === shortName.workspaceSlug);
            return ws ? ws.members : [];
        }
        if (board) {
            const ws = data.find(ws => ws.boards.some(b => b._id === board));
            return ws ? ws.boards.find(b => b._id === board).members : [];
        }
        if (card) {
            const ws = data.find(ws =>
                ws.boards.some(b => b.actionLists.some(al => al.cards.some(c => c._id === card)))
            );
            const boardObj = ws?.boards.find(b =>
                b.actionLists.some(al => al.cards.some(c => c._id === card))
            );
            return boardObj ? boardObj.members : [];
        }

        if (cardId) {
            const workspace = data.find(ws =>
                ws.boards.some(board =>
                    board.actionLists.some(actionList =>
                        actionList.cards.some(card => card._id === cardId)
                    )
                )
            );

            // If the workspace is found, return its members, otherwise return an empty array
            return workspace ? workspace.members : [];
        }
        return [];
    },

    users: (data, { card }) => {
        const ws = data.find(ws =>
            ws.boards.some(b => b.actionLists.some(al => al.cards.some(c => c._id === card)))
        );
        return ws ? ws.boards
            .flatMap(b => b.actionLists)
            .flatMap(al => al.cards)
            .find(c => c._id === card).users : [];
    },
    checklists: (data, { card }) => {
        const ws = data.find(ws =>
            ws.boards.some(b => b.actionLists.some(al => al.cards.some(c => c._id === card)))
        );
        return ws ? ws.boards
            .flatMap(b => b.actionLists)
            .flatMap(al => al.cards)
            .find(c => c._id === card).checklists : [];
    },
    labels: (data, { card }) => {
        const ws = data.find(ws =>
            ws.boards.some(b => b.actionLists.some(al => al.cards.some(c => c._id === card)))
        );
        return ws ? ws.boards
            .flatMap(b => b.actionLists)
            .flatMap(al => al.cards)
            .find(c => c._id === card).labels : [];
    },
    watchers: (data, { card }) => {
        const ws = data.find(ws =>
            ws.boards.some(b => b.actionLists.some(al => al.cards.some(c => c._id === card)))
        );
        return ws ? ws.boards
            .flatMap(b => b.actionLists)
            .flatMap(al => al.cards)
            .find(c => c._id === card).watchers : [];
    },

    recentViewedBoards: (data, { limit }) => {
        const boards = data.flatMap(workspace => workspace.boards);
        boards.sort((a, b) => new Date(b.lastViewed) - new Date(a.lastViewed));

        return limit ? boards.slice(0, limit) : boards;
    },
    filtered: (data, { filters }) => {

        const board = data;
        if (!board || !filters) return null;

        const filteredCards = board.actionLists
            .flatMap(al => al.cards)
            .filter(card => {
                const matchesKeyword = filters.keyword &&
                    (
                        card.title.toLowerCase().includes(filters.keyword.toLowerCase()) ||
                        card.description?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
                        card.users.some(user => user.name.toLowerCase().includes(filters.keyword.toLowerCase())) ||
                        card.users.some(user => user.username.toLowerCase().includes(filters.keyword.toLowerCase())) ||
                        card.labels.some(label => label.text.toLowerCase().includes(filters.keyword.toLowerCase()))
                    );

                const noMembersMatch = filters.noMembers && card.users.length === 0;

                const selectedMembersMatch = filters.selectedMembers?.length &&
                    card.users.some(user => filters.selectedMembers.includes(user._id));

                const assignedToMeMatch = filters.assignedToMe &&
                    card.users.some(user => filters.selectedMembers.includes(user._id));

                const noDatesMatch = filters.noDates && !card.dueDate?.date;

                const overdueMatch = filters.overdue &&
                    (new Date(card.dueDate?.date) < new Date());

                const dueNextDayMatch = filters.dueNextDay &&
                    new Date(card.dueDate?.date).getDate() === new Date().getDate() + 1;

                const noLabelsMatch = filters.noLabels &&
                    card.labels.every(label => !label.enabled);

                const selectedLabelsMatch = filters.selectedLabels?.length &&
                    filters.selectedLabels.some(labelId =>
                        card.labels.some(cardLabel => cardLabel._id === labelId)
                    );

                // Return true if any filter condition matches
                return (
                    matchesKeyword ||
                    noMembersMatch ||
                    selectedMembersMatch ||
                    assignedToMeMatch ||
                    noDatesMatch ||
                    overdueMatch ||
                    dueNextDayMatch ||
                    noLabelsMatch ||
                    selectedLabelsMatch
                );
            });

        console.log(filteredCards.length);
        localStorage.setItem('cardsCount', filteredCards.length);

        return {
            ...board,
            actionLists: board.actionLists.map(al => ({
                ...al,
                cards: filteredCards.filter(card => al.cards.some(c => c._id === card._id))
            }))
        };
    },
    filteredStrict: (data, { boardId, filters }) => {


        const board = data.flatMap(ws => ws.boards).find(b => b._id === boardId);
        if (!board) return null;
        if (!filters) return null;

        const filteredCards = board.actionLists
            .flatMap(al => al.cards)
            .filter(card => {
                let matchesAnyCriteria = false;

                // Keyword filter
                if (filters.keyword &&
                    (
                        card.title.toLowerCase().includes(filters.keyword.toLowerCase()) ||
                        card.description.toLowerCase().includes(filters.keyword.toLowerCase()) ||
                        card.users.some(user => user.name.toLowerCase().includes(filters.keyword.toLowerCase())) ||
                        card.users.some(user => user.username.toLowerCase().includes(filters.keyword.toLowerCase())) ||
                        card.labels.some(label => label.text.toLowerCase().includes(filters.keyword.toLowerCase()))
                    )) {
                    matchesAnyCriteria = true;
                }

                console.log('selected members: ', filters.selectedMembers);


                if (filters.noMembers && card.users.length === 0) {
                    matchesAnyCriteria = true;
                }

                // Members filter
                if (filters.selectedMembers?.length) {
                    if (filters.assignedToMe && card.users.some(user => user._id === filters.assignedToMe)) {
                        matchesAnyCriteria = true;
                    }
                    if (card.users.some(user => filters.selectedMembers.includes(user.username))) {
                        matchesAnyCriteria = true;
                    }
                }

                // Dates filter
                if (filters.noDates && !card.dueDate?.date) {
                    matchesAnyCriteria = true;
                }
                if (filters.overdue && (new Date(card.dueDate?.date) < new Date())) {
                    matchesAnyCriteria = true;
                }
                if (filters.dueNextDay && (new Date(card.dueDate?.date).getDate() === new Date().getDate() + 1)) {
                    matchesAnyCriteria = true;
                }

                if (filters.noLabels && (card.labels.every(label => !label.enabled))) {
                    matchesAnyCriteria = true;
                }

                // Labels filter
                if (filters.selectedLabels?.length) {
                    if (filters.selectedLabels.some(labelId => card.labels.some(cardLabel => cardLabel._id === labelId))) {
                        matchesAnyCriteria = true;
                    }
                }

                return matchesAnyCriteria;
            });

        return {
            ...board,
            actionLists: board.actionLists.map(al => ({
                ...al,
                cards: filteredCards.filter(card => al.cards.some(c => c._id === card._id))
            }))
        };
    }

};



export const find = {
    get: (data, params) => {
        const lookup = lookupFunctions[params.name];
        if (lookup) {
            return lookup(data, params);
        }
        return [];
    }
};

// utils/getScrollableAncestor.js
export const getScrollableAncestor = (element) => {
    let currentElement = element;

    while (currentElement) {
        if (currentElement === document.body) {
            return window;
        }
        const overflowY = window.getComputedStyle(currentElement).overflowY;
        if (overflowY === 'scroll' || overflowY === 'auto') {
            return currentElement;
        }
        currentElement = currentElement.parentElement;
    }

    return window;
};


export const getChecklistStatus = (checklists) => {
    let totalItems = 0;
    let checkedItems = 0;

    checklists && checklists.forEach(checklist => {
        totalItems += checklist.items.length;
        checkedItems += checklist.items.filter(item => item.checked).length;
    });

    return [checkedItems, totalItems];
}


export const getOptionIndex = (currentOption, options) => {
    return options.findIndex(option => option.value === currentOption);
};

export const initialFiltersState = {
    keyword: '',
    noMembers: false,
    selectMembers: false,
    assignedToMe: false,
    noDates: false,
    overdue: false,
    dueNextDay: false,
    noLabels: false,
    selectedMembers: [],
    selectedLabels: [],
    filteredStatus: false,
}


const reorderKeys = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(reorderKeys);
    }

    const ordered = {};
    Object.keys(obj).sort().forEach(key => {
        ordered[key] = reorderKeys(obj[key]);
    });

    return ordered;
}

export const areFiltersEqual = (obj1, obj2) => {

    const copyObj1 = { ...obj1 };
    const copyObj2 = { ...obj2 };

    delete copyObj1.filteredStatus;
    delete copyObj2.filteredStatus;
    delete copyObj2._id;

    if (!copyObj2.keyword) {
        copyObj2.keyword = "";
    }

    // Reorder keys and stringify the objects
    const reorderedObj1 = reorderKeys(copyObj1);
    const reorderedObj2 = reorderKeys(copyObj2);

    // Compare the stringified versions
    return JSON.stringify(reorderedObj1) === JSON.stringify(reorderedObj2);
}


export const relative = (url) => {
    try {
        const { origin, pathname } = new URL(url);
        return origin === window.location.origin ? pathname : url;
    } catch {
        return url;
    }
};

export const mergeRefs = (...refs) => (node) => {
    refs.forEach((ref) => {
        if (typeof ref === 'function') {
            ref(node);
        } else if (ref) {
            ref.current = node;
        }
    });
};


export const getDomain = () => {
    const url = window.location;
    const protocol = url.protocol;
    const hostname = url.hostname;

    return `${protocol}//${hostname}`;

}

export const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    if (isNaN(date.getTime())) {
        throw new Error('Invalid date string');
    }
    const options = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
};

export const textTransform = (text, transformationType) => {
    switch (transformationType) {
        case 'uppercase':
            return text.toUpperCase();
        case 'lowercase':
            return text.toLowerCase();
        case 'capitalize':
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        case 'titlecase':
            return text
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        default:
            return text;
    }
};

export const rgbaColor = (color, opacity) => {
    // Check if the input is a valid hex color
    if (/^#[0-9A-F]{6}$/i.test(color)) {
        // Convert hex to RGB
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`; // Return RGBA
    }

    // Create a temporary element to determine if the color name is valid
    const tempDiv = document.createElement('div');
    tempDiv.style.color = color;

    // Append it to the body to utilize the computed style
    document.body.appendChild(tempDiv);
    const computedColor = window.getComputedStyle(tempDiv).color;
    document.body.removeChild(tempDiv);

    // If the color is valid, parse the RGB values
    if (computedColor !== 'rgb(0, 0, 0)') {
        const rgbValues = computedColor.match(/\d+/g); // Extract numeric values
        return `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${opacity})`; // Return RGBA
    }

    // return `rgba(0, 0, 0, 0)`; // Fallback to transparent
};

export const uniqid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substring(2, 8);
};
export const MIN_NUMBER = 1;
export const MAX_NUMBER = 99999999;

export const getTextColor = (bgColor) => {
    const hex = bgColor?.replace('#', '');
    if (!hex) return;
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > 128 ? '#000000' : '#FFFFFF';
}


export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};


export const debounce = (func, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args), delay);
    };
};