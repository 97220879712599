import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import SearchSelect from './SearchSelect';
import './scss/Feedback.scss';
import { config } from '../../config';

const Feedback = ({ defaultSelectedOption }) => {
    const { workspaces } = useSelector((state) => state.workspaces);
    const dispatch = useDispatch();

    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackUrl, setFeedbackUrl] = useState(window.location.href);
    const [feedbackDescription, setFeedbackDescription] = useState('');
    const [feedbackType, setFeedbackType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false); // New state to track submission

    const feedbackTypeOptions = [
        { value: 'bug', label: '🐞 Bug' },
        { value: 'feature-request', label: '✨ Feature Request' },
        { value: 'ui-suggestion', label: '🎨 UI Suggestion' },
        { value: 'performance', label: '⚡ Performance Issue' },
        { value: 'accessibility', label: '♿ Accessibility Feedback' },
        { value: 'content', label: '📝 Content Feedback' },
        { value: 'security', label: '🔒 Security Concern' },
        { value: 'integration', label: '🔗 Integration Request' },
        { value: 'documentation', label: '📚 Documentation Feedback' },
        { value: 'workflow', label: '🛠️ Workflow Enhancement' },
        { value: 'compatibility', label: '🔄 Compatibility Issue' },
        { value: 'localization', label: '🌐 Localization/Translation Feedback' },
        { value: 'usability', label: '🤔 Usability Feedback' },
        { value: 'support', label: '💬 Support Feedback' },
        { value: 'other', label: '❓ Other' },
    ];

    useEffect(() => {
        const savedFeedback = JSON.parse(localStorage.getItem('feedbackDraft'));
        if (savedFeedback) {
            setFeedbackTitle(savedFeedback.feedbackTitle || '');
            setFeedbackUrl(savedFeedback.feedbackUrl || window.location.href);
            setFeedbackDescription(savedFeedback.feedbackDescription || '');
            setFeedbackType(savedFeedback.feedbackType || null);
        }
    }, []);

    useEffect(() => {
        const draft = {
            feedbackTitle,
            feedbackUrl,
            feedbackDescription,
            feedbackType,
        };
        localStorage.setItem('feedbackDraft', JSON.stringify(draft));
    }, [feedbackTitle, feedbackUrl, feedbackDescription, feedbackType]);

    const handleFeedbackType = (type) => {
        setFeedbackType(type);
    };

    const handleCancel = () => {
        localStorage.removeItem('feedbackDraft');
        dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
    };

    const handleSubmitFeedback = async (e) => {
        e.preventDefault();
        setLoading(true);

        const body = {
            title: feedbackTitle,
            url: feedbackUrl,
            description: feedbackDescription,
            type: feedbackType?.value || 'bug',
        };

        try {
            const token = localStorage.getItem('accessToken');

            const response = await fetch(config.API_URI + '/api/feedback', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                throw new Error('Failed to submit feedback');
            }

            localStorage.removeItem('feedbackDraft');
            setIsSubmitted(true); // Show thank-you message
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }

        setLoading(false);
    };

    return (
        <div className="create-feedback-form">
            {isSubmitted ? (
                <div className="thank-you-message">
                    <div className="checkmark">✔</div>
                    <h2>Thank you for your feedback!</h2>
                    <p>Your input helps us improve our product.</p>
                </div>
            ) : (
                <form onSubmit={handleSubmitFeedback}>
                    <h1>Share Your Thoughts</h1>

                    <div className="group">
                        <p className='feedback-page-link'>Want to see what others think? Visit the <a href="/feedback" target='_blank'>feedback page</a>.</p>
                    </div>

                    <div className="group">
                        <h3 htmlFor="feedback-title">Title *</h3>
                        <input
                            type="text"
                            id="feedback-title"
                            value={feedbackTitle}
                            onChange={(e) => setFeedbackTitle(e.target.value)}
                            placeholder="Enter Board Title"
                            className={!feedbackTitle ? 'error' : ''}
                        />
                    </div>

                    <div className="group">
                        <h3>Feedback Type*</h3>
                        <SearchSelect
                            options={feedbackTypeOptions}
                            onSelect={handleFeedbackType}
                            placeholder="Select an option"
                            isGrouped={false}
                            defaultValue={feedbackType || 'bug'}
                        />
                    </div>

                    <div className="group">
                        <h3 htmlFor="feedback-url">Page Url</h3>
                        <input
                            type="url"
                            id="feedback-url"
                            value={feedbackUrl}
                            onChange={(e) => setFeedbackUrl(e.target.value)}
                            placeholder="Enter url here"
                            className={!feedbackUrl ? 'error' : ''}
                        />
                    </div>

                    <div className="group">
                        <h3 htmlFor="workspace-description">
                            Describe what's happening *
                        </h3>
                        <textarea
                            id="workspace-description"
                            value={feedbackDescription}
                            onChange={(e) => setFeedbackDescription(e.target.value)}
                            placeholder="Remember not to include personal info like phone numbers"
                        />
                    </div>

                    <div className="group feedback-submit-buttons">
                        <button className="cancel-button" type="button" onClick={handleCancel}>
                            Cancel
                        </button>
                        <button
                            className={`submit-button ${(!feedbackTitle || !feedbackDescription || loading) ? 'disabled' : ''}`}
                            type="submit"
                            disabled={!feedbackTitle || !feedbackDescription || loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default Feedback;
