import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import './scss/CardTable.scss';
import ProfileNavbar from './ProfileNavbar';
import { find, toSlug } from '../../utils/helpers';
import { config } from '../../config';
import Spinner from '../Global/Spinner';

const CardTable = () => {
    const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);
    const workspaces = useSelector((state) => state.workspaces); // Example
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        if (!user) return; // Make sure the effect doesn't run if user is missing

        const fetchCards = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(config.API_URI + `/api/${user?.user?._id}/cards`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch cards');
                }

                const data = await response.json();
                setCards(data);
            } catch (error) {
                console.error('Error fetching cards:', error);
            } finally {
                setLoading(false);  // Set loading to false when data is fetched
            }
        };

        fetchCards();
    }, [user]);


    return (
        <div className={`profile-page${loading ? ' card-loading' : ''}`}>
            <ProfileNavbar />
            <div className="card-table">
                <div className="table-header">
                    <div className="header-item">Card</div>
                    <div className="header-item">List</div>
                    <div className="header-item">Labels</div>
                    <div className="header-item">Due date</div>
                    <div className="header-item">Board</div>
                </div>
                <div className={`table-body`}>
                    {
                        loading && (
                            <Spinner size={30} color="#3498db" speed={1.5} />
                        )
                    }
                    {!loading && cards && cards.map((card, index) => {

                        return (
                            <div key={index} className="table-row">
                                <Link to={card.permalink}>
                                    <div className="table-cell">{card.title}</div>
                                </Link>
                                <div className="table-cell">{card.actionList.title || 'N/A'}</div>
                                <div className="table-cell">
                                    <div className="labels">
                                        {card.labels && card.labels.map((label, labelIndex) => (
                                            <span key={labelIndex} style={{ backgroundColor: label.color }} className={`label`}></span>
                                        ))}
                                    </div>
                                </div>
                                <div className="table-cell">
                                    {card.dueDate.date && (
                                        <>
                                            {format(card.dueDate.date, 'MMM dd')}, {card.dueDate.dueTime}

                                        </>
                                    )}


                                </div>
                                <Link to={`${card.boardLink}`}>
                                    <div className="table-cell board-info">
                                        <span>{card.boardTitle}</span>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div >
    );
};

export default CardTable;
