import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Async thunk to fetch an action list by ID
export const fetchActionListById = createAsyncThunk(
    'actionList/fetchActionListById',
    async (actionListId, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/actionLists/${actionListId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch action list');
        }

        const data = await response.json();
        return { actionListId, actionList: data.actionList };
    }
);

// Async thunk to fetch cards by action list ID
export const fetchCardsByListId = createAsyncThunk(
    'actionList/fetchCardsByListId',
    async (actionListId, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/actionLists/${actionListId}/cards`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch cards');
        }

        const data = await response.json();
        return { actionListId, cards: data.cards };
    }
);

const actionListSlice = createSlice({
    name: 'actionList',
    initialState: {
        actionLists: {}, // Stores action lists by ID
        status: 'idle',
        error: null,
    },
    reducers: {
        // Add synchronous reducers if needed
    },
    extraReducers: (builder) => {
        builder
            // Fetch action list by ID
            .addCase(fetchActionListById.pending, (state, action) => {
                const actionListId = action.meta.arg;
                state.actionLists[actionListId] = state.actionLists[actionListId] || { details: null, cards: [], status: 'idle', error: null };
                state.actionLists[actionListId].status = 'loading';
            })
            .addCase(fetchActionListById.fulfilled, (state, action) => {
                const { actionListId, actionList } = action.payload;
                state.actionLists[actionListId] = state.actionLists[actionListId] || { details: null, cards: [], status: 'idle', error: null };
                state.actionLists[actionListId].details = actionList;
                state.actionLists[actionListId].status = 'succeeded';
            })
            .addCase(fetchActionListById.rejected, (state, action) => {
                const actionListId = action.meta.arg;
                state.actionLists[actionListId] = state.actionLists[actionListId] || { details: null, cards: [], status: 'idle', error: null };
                state.actionLists[actionListId].status = 'failed';
                state.actionLists[actionListId].error = action.error.message;
            })

            // Fetch cards by action list ID
            .addCase(fetchCardsByListId.pending, (state, action) => {
                const actionListId = action.meta.arg;
                state.actionLists[actionListId] = state.actionLists[actionListId] || { details: null, cards: [], status: 'idle', error: null };
                state.actionLists[actionListId].status = 'loading';
            })
            .addCase(fetchCardsByListId.fulfilled, (state, action) => {
                const { actionListId, cards } = action.payload;
                state.actionLists[actionListId] = state.actionLists[actionListId] || { details: null, cards: [], status: 'idle', error: null };
                state.actionLists[actionListId].cards = cards;
                state.actionLists[actionListId].status = 'succeeded';
            })
            .addCase(fetchCardsByListId.rejected, (state, action) => {
                const actionListId = action.meta.arg;
                state.actionLists[actionListId] = state.actionLists[actionListId] || { details: null, cards: [], status: 'idle', error: null };
                state.actionLists[actionListId].status = 'failed';
                state.actionLists[actionListId].error = action.error.message;
            });
    },
});

export default actionListSlice.reducer;
