import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './scss/SetPasswordBanner.scss';

const SetPasswordBanner = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user?.user);
    const isDefaultPasswordSet = user?.isDefaultPasswordSet;

    console.log(user);

    // If the password is already set, do not display the banner
    if (isDefaultPasswordSet) return null;

    return (
        <div className="banner">

            <p>
                <span className="material-symbols-outlined">
                    info
                </span>
                Please set your password to complete your account setup.
            </p>
            <Link className="banner-password-link" to="/set-password">Set Password</Link>
        </div>
    );
};

export default SetPasswordBanner;
