import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';
import StarFill from '../icons/StarFill';
import { config } from '../../config';
import { useParams } from 'react-router-dom';
import { fetchStarredBoards } from '../../redux/Slices/boardsSlice';
import { fetchUser } from '../../redux/Slices/thunks';

const NavbarLeft = ({ boardId, board, setBoard }) => {
    const dispatch = useDispatch();

    const { handlePopoverClick } = useHandlePopoverClick();

    const { user } = useSelector((state) => state.user);

    const [isStarred, setIsStarred] = useState(user.user.starredBoards?.includes(board._id) || false);


    // Toggle star status
    const toggleStar = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const action = isStarred ? 'remove' : 'add';

            const response = await fetch(`${config.API_URI}/api/users/me/stared`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ boardId, action }),
            });

            if (!response.ok) throw new Error('Failed to update star status');

            setIsStarred(!isStarred); // Toggle star state
            dispatch(fetchUser());
            dispatch(fetchStarredBoards());
        } catch (error) {
            console.error('Error toggling star status:', error);
        }
    };

    return (
        <ul className="zoobbe-navbar-left">
            <li className="zoobbe-workspace-name">{board?.title}</li>
            <li className="zoobbe-star" onClick={toggleStar}>
                {!isStarred ? (
                    <span className="material-symbols-outlined">
                        kid_star
                    </span>
                ) : (
                    <StarFill color={isStarred ? '#FFD700' : '#fff'} />

                )}
            </li>
            <li
                className="zoobbe-board-visibility"
                onClick={(e) => handlePopoverClick(e, 'boardVisibility', { boardId, visibility: board.visibility, setBoard })}
                data-popover-trigger
            >
                {board?.visibility === 'Workspace' && <span className="material-symbols-outlined">group</span>}
                {board?.visibility === 'Public' && <span className="material-symbols-outlined">public</span>}
                {board?.visibility === 'Private' && <span className="material-symbols-outlined">lock</span>}
                {!board?.visibility && <span className="material-symbols-outlined">lock</span>} {/* Default to private */}
                {board?.visibility
                    ? board.visibility === 'Workspace'
                        ? `${board.visibility} visibility`
                        : board.visibility
                    : 'Private'}
            </li>
            <li>
                <button className="zoobbe-board-button">
                    <span className="material-symbols-outlined">
                        view_kanban
                    </span>
                    Board
                </button>
            </li>
        </ul>
    );
};

export default NavbarLeft;
