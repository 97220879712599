import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './scss/CardTable.scss';
import ProfileNavbar from './ProfileNavbar';
import { find, toSlug } from '../../utils/helpers';
import { config } from '../../config';

const CardTable = () => {

    const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);
    const workspaces = useSelector((state) => state.workspaces); // Example
    const [cards, setCards] = useState([]);

    useEffect(() => {
        if (!user) return; // Make sure the effect doesn't run if user is missing

        const fetchCards = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(config.API_URI + `/api/${user?.user?._id}/cards`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch cards');
                }

                const data = await response.json();
                setCards(data);
            } catch (error) {
                console.error('Error fetching cards:', error);
            }
        };

        fetchCards();
    }, [user]);

    if (!user) {
        return <div>Loading...</div>; // Return loading UI
    }


    return (
        <div className="profile-page">
            <ProfileNavbar />
            <div className="card-table">
                <div className="table-header">
                    <div className="header-item">Card</div>
                    <div className="header-item">List</div>
                    <div className="header-item">Labels</div>
                    <div className="header-item">Due date</div>
                    <div className="header-item">Board</div>
                </div>
                <div className="table-body">
                    {cards && cards.map((card, index) => {
                        return (
                            <div key={index} className="table-row">
                                <Link to={`/c/${card._id}/${toSlug(card.title)}`} >
                                    <div className="table-cell">{card.title}</div>
                                </Link>
                                <div className="table-cell">{card.actionList.title || 'N/A'}</div>
                                <div className="table-cell">
                                    <div className="labels">
                                        {card.labels && card.labels.filter(label => label.enabled).map((label, labelIndex) => (
                                            <span key={labelIndex} style={{ backgroundColor: label.color }} className={`label`}></span>
                                        ))}
                                    </div>
                                </div>
                                <div className="table-cell"></div>
                                <Link to={`${card.boardLink}`}>
                                    <div className="table-cell board-info">
                                        <span>{card.boardTitle}</span>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default CardTable;
