import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useVirtualizer } from '@tanstack/react-virtual';
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { showToast } from '../../redux/Slices/toastSlice';
import { fetchUsers } from '../../redux/Slices/thunks';
import { config } from '../../config';
import { useBoard } from '../../context/BoardContext';
import { debounce, find, getFilters } from '../../utils/helpers';
import { selectBoardId, setBoardId } from '../../redux/Slices/boardIdSlice';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { setFilterQuery } from '../../redux/Slices/filterQuerySlice';
import ActionList from './ActionList';
import ActionListVirtualized from './ActionListVirtualized';
import ActionListPND from './ActionListPND';
import AddActionListButton from './AddActionListButton';
import Navbar from './Navbar';
import NotFound from '../400';
import useIsScrolling from '../../hooks/useIsScrolling';
import SkeletonBoard from '../Skeletons/SkeletonBoard';
import { fetchBoardById } from '../../redux/Slices/boardSlice';
import { Helmet } from 'react-helmet';
import CardDetails from './CardDetails';
import { fetchMembers } from '../../redux/Slices/memberSlice';
import { fetchCardsByListId } from '../../redux/Slices/actionlistSlice';

const Board = ({ isCardPath, board, setBoard }) => {
  const { boardId, boardSlug, cardId } = useParams();
  const { setBoardPermalink } = useBoard();
  const _boardId = useSelector(selectBoardId);

  const dispatch = useDispatch();
  const location = useLocation();


  const [showForm, setShowForm] = useState(false);
  const [listTitle, setActionListTitle] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [openListId, setOpenListId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedLists, setFetchedLists] = useState(new Set());
  const [highlight, setHighlight] = useState(false);
  const [cardCount, setCardCount] = useState(0);

  const textareaRef = useRef(null);
  const parentRef = useRef(null);
  const boardRef = useRef(null);
  const formRef = useRef(null);
  const isScrolling = useIsScrolling(parentRef);

  const query = useSelector((state) => state.filterQuery);

  // Monitor Drag and Drop
  useEffect(() => {
    const element = boardRef.current;
    const monitorConfig = {
      element,
      onDrag({ location }) {
        const target = location.current.dropTargets[0];
        setHighlight(target && target.data.boardShortId === _boardId);
      },
      onDrop() {
        setHighlight(false);
      },
    };

    return monitorForElements(monitorConfig);
  }, [_boardId]);

  // Virtualizer configuration
  const virtualizer = useVirtualizer({
    horizontal: true,
    count: board.actionLists.length + 1,
    getScrollElement: () => parentRef.current || boardRef.current,
    estimateSize: index => {
      return board.actionLists[index]?.customWidth || 280; // Replace `customWidth` with the actual dynamic size logic
    },
  });

  // Fetch filters on component mount
  useEffect(() => {
    const fetchFilters = async () => {
      const { filters, cardCount } = await getFilters({ boardId });
      dispatch(setFilterQuery(filters));
      setCardCount(cardCount);
    };
    fetchFilters();
  }, [boardId, dispatch]);


  useEffect(() => {
    const fetchBoardData = async () => {
      if (!boardId && !_boardId) return;

      setIsLoading(true);

      try {
        // Fetch board data
        const boardData = await dispatch(fetchBoardById(boardId || _boardId)).unwrap();

        // Set initial board with empty cards for all lists
        setBoard({
          ...boardData,
          actionLists: boardData.actionLists.map((list) => ({ ...list })),
        });

      } catch (error) {
        console.error("Error fetching the board:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBoardData();
  }, [_boardId, dispatch]);

  const fetchCardsForList = async (listId) => {
    try {
      const { cards } = await dispatch(fetchCardsByListId(listId)).unwrap();
      // setBoard((prevBoard) => ({
      //   ...prevBoard,
      //   actionLists: prevBoard.actionLists.map((list) =>
      //     list._id === listId ? { ...list, cards } : list
      //   ),
      // }));

      setBoard((prevBoard) => {
        const updatedActionLists = prevBoard.actionLists.map((list) =>
          list._id === listId ? { ...list, cards: cards || list.cards } : list
        );

        return { ...prevBoard, actionLists: updatedActionLists };
      });

    } catch (error) {
      console.error(`Error fetching cards for list ${listId}:`, error);
    }
  };

  const debouncedFetchCardsForList = useCallback(debounce(fetchCardsForList, 15), []);



  useEffect(() => {
    if (boardId && _boardId !== boardId) {
      dispatch(setBoardId(boardId));
    }
  }, [boardId, _boardId, dispatch]);



  useEffect(() => {
    if (!board.wShortId) return;
    dispatch(fetchMembers({ type: 'workspace', id: board.wShortId }));
  }, [dispatch, board.wShortId]);


  // Handle adding new action list
  const handleAddList = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(`${config.API_URI}/api/actionLists`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ boardId: _boardId, title: listTitle }),
      });

      if (!response.ok) throw new Error('Failed to add action list');

      const data = await response.json();
      const newList = data.actionList;

      setBoard((prev) => ({
        ...prev,
        actionLists: [...prev.actionLists, newList],
      }));

      setActionListTitle('');
      setShowForm(true); // Reopen form
      setTimeout(() => {
        textareaRef.current?.focus();
        formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Scroll into view again
      }, 0);

      dispatch(showToast({ message: 'Action list added successfully!', type: 'success' }));
    } catch (error) {
      console.error('Error adding action list:', error);
      dispatch(showToast({ message: 'Error adding action list', type: 'error' }));
    }
  };


  // Handle textarea height adjustment
  useEffect(() => {
    if (textareaRef.current) {
      const resetHeight = () => {
        textareaRef.current.style.height = '20px';
        const scrollHeight = textareaRef.current.scrollHeight - 20;
        textareaRef.current.style.height = `${scrollHeight}px`;
      };

      resetHeight();
    }
  }, [listTitle]);

  const handleFetchList = useCallback((list, actualIndex) => {

    if (list && !fetchedLists.has(list._id)) {

      setFetchedLists((prev) => new Set(prev).add(list._id));

      if (actualIndex > 6) {
        debouncedFetchCardsForList(list._id);
      } else {
        fetchCardsForList(list._id);
      }
    }

  }, [debouncedFetchCardsForList, fetchedLists]);



  // Loading state handling
  if (isLoading && (!board || board.actionLists.length < 1)) {
    return <SkeletonBoard />;
  }


  // Event handlers for form visibility
  const handleShowForm = () => {
    setShowForm(true);
    setTimeout(() => {
      textareaRef.current?.focus();
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Scroll into view
    }, 0);
  };

  const handleHideForm = () => setShowForm(false);
  const handleToggleOptions = (listId) => setOpenListId((prev) => (prev === listId ? null : listId));
  const handleDeleteList = (listId) => setBoard((prev) => ({
    ...prev,
    actionLists: prev.actionLists.filter((list) => list._id !== listId),
  }));

  // Styles based on dragging state
  const style = {
    ...(highlight ? { border: '2px dotted #ddd' } : {})
  };


  return (
    <>
      {board.title && (
        <Helmet>
          <title>{board.title} | Zoobbe</title>
        </Helmet>
      )}

      <Navbar
        cardCount={cardCount}
        board={board}
        setBoard={setBoard}
        isLoading={isLoading}
      />
      <div
        className={`zoobbe-workspace-board-container${isScrolling ? ' scrolling' : ''}`}
        ref={parentRef}
        style={{
          width: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <ol
          className='zoobbe-board'
          style={{
            // ...(highlight ? { backgroundColor: 'blue' } : { backgroundColor: 'red' }),
            width: virtualizer.getTotalSize() + (virtualizer.getVirtualItems().length + 1) * 10,
            // width: virtualizer.getTotalSize() + (virtualizer.getVirtualItems().length + 1) * 20,
            height: 'calc(-125px + 100vh)',
            position: 'relative',

          }}
          ref={boardRef}
        >
          {virtualizer.getVirtualItems().map((virtualColumn, index) => {

            const itemWidth = 280; // Your item width
            const actualIndex = Math.floor(virtualColumn.start / itemWidth);
            const list = board.actionLists[actualIndex];

            // Ensure actualIndex is within bounds
            if (actualIndex >= 0 && actualIndex < board.actionLists.length) {
              handleFetchList(list, actualIndex);
            }

            return (
              <li
                className='zoobbe-action-list'
                key={list?._id || 'add-list-form'}
                ref={el => {
                  if (el) virtualizer.measureElement(el);
                }}
                data-index={index}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: 280,
                  transform: `translateX(${virtualColumn.start + (index * 10)}px)`
                  // transform: `translateX(${virtualColumn.start}px)`
                }}

              >
                {list ? (
                  <ActionListPND
                    number={virtualColumn.index}
                    actionList={list}
                    isOpen={openListId === list._id}
                    onToggleOptions={() => handleToggleOptions(list._id)}
                    onDelete={handleDeleteList}
                    board={{ ...board }}
                    setBoard={setBoard}
                    boardId={board.shortId}
                    boardScrollRef={parentRef}
                  />
                ) : (
                  // Render the "Add List" button and form at the end
                  <div className="action-list-button-wrapper" ref={formRef}>
                    {showForm ? (
                      <form onSubmit={handleAddList} style={{ width: '100%', padding: '10px' }}>
                        <textarea
                          className="add-new-list"
                          ref={textareaRef}
                          name="add-new-list"
                          placeholder="Enter list title"
                          rows="1"
                          value={listTitle}
                          onChange={(e) => setActionListTitle(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleAddList(e);
                            }
                          }}
                          required
                        />
                        <div className="footer-buttons">
                          <button type="submit">Add List</button>
                          <button type="button" onClick={handleHideForm}>
                            <span className="material-symbols-outlined">close</span>
                          </button>
                        </div>
                      </form>
                    ) : (
                      <>
                        {
                          !isLoading && (
                            <AddActionListButton onClick={handleShowForm} />
                          )
                        }
                      </>
                    )}
                  </div>
                )}
              </li>
            )
          })}
        </ol>
      </div>

    </>
  );
};

export default Board;
