import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './scss/CopyCard.scss';
import { fetchChecklists } from '../../redux/Slices/checklistSlice';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { config } from '../../config';
import { fetchActivities } from '../../redux/Slices/activitiesSlice';
import { fetchBoards, fetchBoardLists } from '../../redux/Slices/boardsSlice';
import SearchSelect from '../Global/SearchSelect';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';
import Checkbox from '../Global/Checkbox';
import { useNavigate } from 'react-router-dom';

const CopyCard = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const boardId = useSelector(selectBoardId);
    const { boards, actionLists } = useSelector(state => state.boards);
    const { card } = useSelector(state => state.card);
    const cardId = card.shortId;
    const [title, setTitle] = useState('Checklist');
    const [cardTitle, setCardTitle] = useState(card.title || '');
    const [isTextareaFocused, setIsTextareaFocused] = useState(false); // New focus state
    const [isChecked, setIsChecked] = useState(false);

    const textareaRef = useRef(null);

    useEffect(() => {
        dispatch(fetchBoards());
    }, [dispatch]);


    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = '22px';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [cardTitle]);


    const [moveState, setMoveState] = useState({
        selectedBoard: boardId || null,
        selectedList: card.actionList._id || null,
        selectedPosition: card.shortId || null,
        targetPosition: null,
        loading: false,
    });

    useEffect(() => {
        dispatch(fetchBoards());
    }, [dispatch]);

    useEffect(() => {
        if (moveState.selectedBoard && !actionLists[moveState.selectedBoard]) {
            dispatch(fetchBoardLists(moveState.selectedBoard));
        }
    }, [moveState.selectedBoard, actionLists, dispatch]);

    useEffect(() => {
        if (moveState.selectedBoard && moveState.selectedBoard !== boardId) {
            const lists = actionLists[moveState.selectedBoard] || [];
            const firstList = lists.length > 0 ? lists[0] : null;

            setMoveState(prev => ({
                ...prev,
                selectedList: firstList?._id || null,
                selectedPosition: firstList?.cards?.length > 0 ? firstList.cards[firstList.cards.length - 1].shortId : 1,
            }));

        }
    }, [moveState.selectedBoard, actionLists, boardId]);

    const boardOptions = useMemo(() => {
        return boards.map(board => ({
            value: board.shortId,
            label: `${board.title}${board.shortId === boardId ? '<br><span className="current-option">(current)</span>' : ''}`,
            workspaceShortId: board.workspaceShortId,
            workspaceName: board.workspaceName,
        })) || [];
    }, [boards, boardId]);

    const actionListOptions = useMemo(() => {
        const lists = actionLists[moveState.selectedBoard] || [];
        return lists.map(actionList => ({
            value: actionList._id.toString(),
            label: `${actionList.title}${actionList._id.toString() === card.actionList._id ? '<br><span className="current-option">(current)</span>' : ''}`,
        }));
    }, [actionLists, moveState.selectedBoard, card.actionList._id]);

    const positionOptions = useMemo(() => {
        const lists = actionLists[moveState.selectedBoard] || [];
        const selectedActionList = lists.find(actionList => actionList._id === moveState.selectedList);

        return selectedActionList?.cards.map((indexCard, index) => ({
            value: indexCard.shortId,
            label: `${index + 1} ${indexCard.shortId === card.shortId ? '<br><span className="current-option">(current)</span>' : ''}`,
            index,
        })) || [];
    }, [actionLists, moveState.selectedBoard, moveState.selectedList, card.shortId]);

    const handleBoardSelect = useCallback((selectedBoardOption) => {
        setMoveState(prev => ({
            ...prev,
            selectedBoard: selectedBoardOption.value,
            selectedList: null,
            selectedPosition: null,
        }));
    }, []);

    const handleActionListSelect = useCallback((selectedListOption) => {
        const lists = actionLists[moveState.selectedBoard] || [];
        const actionList = lists.find(actionList => actionList._id === selectedListOption.value);
        setMoveState(prev => ({
            ...prev,
            selectedList: selectedListOption.value,
            selectedPosition: actionList?.cards?.length > 0 ? actionList.cards[actionList.cards.length - 1].shortId : card.shortId,
        }));
    }, [actionLists, moveState.selectedBoard, card.shortId]);

    const handlePositionSelect = useCallback((selectedPosition) => {
        setMoveState(prev => ({
            ...prev,
            selectedPosition: selectedPosition.value,
            targetPosition: selectedPosition.index,
        }));
    }, []);



    const handleCopyCard = async () => {
        if (!cardTitle) {
            alert('Please enter a title for the checklist');
            return;
        }

        const lists = actionLists[moveState.selectedBoard] || [];
        const selectedActionList = lists.find(actionList => actionList._id === moveState.selectedList);
        const targetPositionIndex = selectedActionList?.cards.findIndex(card => card.shortId === moveState.selectedPosition);

        if (targetPositionIndex === undefined || targetPositionIndex === -1) {
            alert('Please select a valid position.');
            return;
        }

        try {
            setMoveState(prev => ({ ...prev, loading: true })); // Start loading

            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/copy`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    isMembers: isChecked,
                    order: targetPositionIndex + 1
                })
            });

            if (response.ok) {
                const { card } = await response.json();
                dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
                navigate(card.permalink);

            } else {
                console.error('Failed to add checklist');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setMoveState(prev => ({ ...prev, loading: false })); // Stop loading
        }
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
            textareaRef.current.select();
        }
    }, []);

    return (
        <div className="copy-card">
            <h2>Copy Card</h2>

            <div className="group">
                <h3>Title</h3>
                <div className={`textarea-wrapper ${isTextareaFocused ? 'focused' : ''}`}>
                    <textarea
                        ref={textareaRef}
                        type="text"
                        placeholder="Enter card title"
                        value={cardTitle}
                        onChange={e => setCardTitle(e.target.value)}
                        rows={1}
                        style={{ overflow: 'hidden' }}
                        spellCheck={false}
                        onFocus={() => setIsTextareaFocused(true)}  // Set focus state
                        onBlur={() => setIsTextareaFocused(false)}   // Remove focus state
                    />
                </div>
            </div>
            <div className="group">
                <h3>Keep members</h3>
                <div className='keep-members'>
                    <Checkbox
                        checked={isChecked}
                        onChange={(checked) => setIsChecked(checked)}
                    />
                    <span className='label' onClick={() => setIsChecked(!isChecked)}>Members({card.users.length})</span>
                </div>
            </div>

            <div className="group">
                <h3>Select Destination</h3>
                <SearchSelect
                    options={boardOptions}
                    onSelect={handleBoardSelect}
                    placeholder="Select a board"
                    isGrouped={true}
                    defaultValue={moveState.selectedBoard}
                />
            </div>

            <div className='actionlists-group'>
                <div className="group actionlists">
                    <h3>List</h3>
                    <SearchSelect
                        options={actionListOptions}
                        onSelect={handleActionListSelect}
                        placeholder="Select a list"
                        isGrouped={false}
                        defaultValue={moveState.selectedList}
                    />
                </div>
                <div className="group position">
                    <h3>Position</h3>
                    <SearchSelect
                        options={positionOptions}
                        onSelect={handlePositionSelect}
                        placeholder="Select an option"
                        isGrouped={false}
                        defaultValue={moveState.selectedPosition}
                    />
                </div>
            </div>

            <button onClick={handleCopyCard}>
                {moveState.loading ? 'Copying...' : 'Copy Card'}
            </button>
        </div>
    );
};

export default CopyCard;
