import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { find, getTextColor, toSlug } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/Slices/modalSlice";

import './scss/BaordSection.scss'
import MemberImage from "../Global/MemberImage";

import StarFill from "../icons/StarFill";
import { fetchRecentBoards, fetchStarredBoards } from "../../redux/Slices/boardsSlice";
import { config } from "../../config";
import { fetchUser } from "../../redux/Slices/thunks";

const RecentView = () => {

    const dispatch = useDispatch();

    const { workspaces } = useSelector(state => state.workspaces);


    const addTransparency = (rgbColor, alpha = 1) => {
        // Ensure the RGB color is in the correct format (e.g., "rgb(255, 0, 0)")
        const match = rgbColor?.match(/\d+/g);
        if (!match || match.length < 3) {
            return rgbColor; // Return original if format is invalid
        }

        const [r, g, b] = match;
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    const { user } = useSelector((state) => state.user);

    const { recentBoards, cardCount } = useSelector((state) => state.boards);
    const slicedBoards = recentBoards?.slice(0, 3);





    useEffect(() => {
        dispatch(fetchRecentBoards({ limit: 10 }));
    }, [dispatch]);



    // Toggle star status
    const toggleStar = async (boardId, shortId) => {
        try {
            const token = localStorage.getItem('accessToken');
            const isStarred = user.user.starredBoards?.includes(boardId);
            const action = isStarred ? 'remove' : 'add';

            const response = await fetch(`${config.API_URI}/api/users/me/stared`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ boardId: shortId, action }),
            });

            if (response.ok) {
                dispatch(fetchStarredBoards());
                dispatch(fetchUser());
            } else {
            }
        } catch (error) {
            console.error('Error toggling star status:', error);
        }
    };

    return (
        <div className="boards-page-board-section-list">
            {slicedBoards?.map(board => {
                // const members = find.get(workspaces, { name: 'members', board: board._id });
                const cards = find.get(workspaces, { name: 'cards', board: board._id });
                const members = (board.members).filter(member => member._id);

                const coverColor =
                    Array.isArray(board?.cover?.coverColor) && board.cover.coverColor.length > 2
                        ? board.cover.coverColor[2]
                        : 'transparent';

                const opacity = '0.6';


                return (
                    <div
                        className="boards-page-board-section-list-item"
                        key={board._id}
                        style={{
                            backgroundImage: `url(${board?.cover?.url})`,
                            backgroundSize: 'cover', // Ensures the image covers the full container
                            backgroundPosition: 'center center', // Centers the image
                            borderColor: `${coverColor ? addTransparency(coverColor, opacity) : 'transparent'}`

                        }}
                    >
                        {
                            coverColor && (
                                <div
                                    className="board-gradient"
                                    style={{
                                        background: `${coverColor ? addTransparency(coverColor, opacity) : 'transparent'}`
                                    }}
                                ></div>
                            )
                        }

                        <div className="board-item">

                            <div className="top-stats">
                                <div className="board-title" style={{ color: `${getTextColor(coverColor)}` }}> {board.title}</div>

                                <div className="zoobbe-star" onClick={() => { toggleStar(board._id, board.shortId) }}>
                                    {!user.user.starredBoards.includes(board._id) ? (
                                        <span
                                            className="material-symbols-outlined"
                                        >
                                            kid_star
                                        </span>
                                    ) : (
                                        <StarFill
                                            color={user.user.starredBoards.includes(board._id) ? '#FFD700' : '#fff'}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="board-details">
                                <div className="board-members">
                                    <MemberImage members={members} type={'board'} size={30} />
                                    {
                                        members.length > 3 && (
                                            <span className="member-count">+{members.length - 3}</span>
                                        )
                                    }
                                </div>
                                <div className="board-stats" style={{ color: `${getTextColor(coverColor)}` }}>
                                    <span className="material-symbols-outlined" style={{ color: `${getTextColor(coverColor)}` }}>
                                        credit_card
                                    </span>
                                    <span>{board.cardCount}</span>
                                </div>
                            </div>
                        </div>
                        <Link className="board-tile-link" to={board.permalink}></Link>
                    </div>
                )
            })}
        </div>
    )
}

export default RecentView;