import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Asynchronous thunk to fetch card by ID
export const fetchCardById = createAsyncThunk(
    'card/fetchCardById',
    async (cardId, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/cards/${cardId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch card');
        }

        const data = await response.json();


        return data;
    }
);

// Asynchronous thunk to update card
export const updateCard = createAsyncThunk(
    'card/updateCard',
    async ({ cardId, updatedData }, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/cards/update/${cardId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedData)
        });

        if (!response.ok) {
            throw new Error('Failed to update card');
        }

        const data = await response.json();


        return data;  // Returns the updated card
    }
);

const cardSlice = createSlice({
    name: 'card',
    initialState: {
        card: null,
        status: 'idle', // For tracking the status (loading, succeeded, failed)
        error: null
    },
    reducers: {
        cardData: (state, action) => {
            state.card = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCardById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCardById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.card = action.payload || null; // Ensure card is set to null if action.payload is empty
            })
            .addCase(fetchCardById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            // Handling the updateCard cases
            .addCase(updateCard.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateCard.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Ensure that action.payload contains a valid card object before assigning
                state.card = action.payload ? action.payload : state.card; // Update the card only if payload is valid
            })
            .addCase(updateCard.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { cardData } = cardSlice.actions;
export default cardSlice.reducer;
