import React from 'react';
import { useDispatch } from 'react-redux';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { memberInfo } from '../../redux/Slices/memberSlice';
import ImagePlaceholder from './ImagePlaceholder';
import { uniqid } from '../../utils/helpers';

const MemberImage = ({ cardId, members, size, type, displayMembersLimit = 3 }) => {
    const dispatch = useDispatch();

    const handleMemberInfo = (e, contentId, member) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };
        dispatch(togglePopover({ contentId, position, targetId: target.id }));
        dispatch(memberInfo({ member }));
    };

    // If type is "board", show only the first 4 members
    const displayedMembers = type === 'board' ? members.slice(0, displayMembersLimit) : members;

    return (
        <div className='members-info'>
            {
                displayedMembers?.map((member, index) => {
                    if (!member._id) return null;

                    return (
                        member.profilePicture ? (
                            <span
                                id={`member-info-${cardId || uniqid()}-${member._id}`}
                                className='avatar'
                                key={index}
                                onClick={(e) => handleMemberInfo(e, 'memberInfo', member)}
                                data-popover-trigger
                            >
                                <img style={{ width: size, height: size }} src={member.profilePicture} alt={member.name} />
                            </span>
                        ) : (
                            <span
                                id={`member-info-${cardId || uniqid()}-${member._id}`}
                                className='avatar'
                                key={index}
                                onClick={(e) => handleMemberInfo(e, 'memberInfo', member)}
                                data-popover-trigger
                            >
                                <ImagePlaceholder size={size} text={member.username} />
                            </span>
                        )
                    )
                })
            }
        </div>
    );
};

export default MemberImage;
