import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideSnackBar, showSnackBar } from '../../../redux/Slices/snackbarSlice';
import { config } from '../../../config';
import { fetchBoardById } from '../../../redux/Slices/boardSlice';

import Spinner from '../../Global/Spinner';

// Debounce utility function
const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
};

const Unsplash = () => {
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [unsplashImages, setUnsplashImages] = useState([]);
    const [isLoadingUnsplash, setIsLoadingUnsplash] = useState(false);
    const [page, setPage] = useState(1); // Current page for Unsplash API
    const [hasMoreImages, setHasMoreImages] = useState(true);
    const { status } = useSelector((state) => state.attachments);
    const { board } = useSelector((state) => state.board);

    const urlRef = useRef(null);
    const observer = useRef();

    const boardId = board.shortId;
    const dispatch = useDispatch();

    useEffect(() => {
        if (urlRef.current) {
            urlRef.current.style.height = '22px';
            urlRef.current.style.height = `${urlRef.current.scrollHeight}px`;
        }
    }, [attachmentUrl]);

    useEffect(() => {
        if (status === 'uploadSucceeded') {
            dispatch(hideSnackBar());
        } else if (status === 'uploadFailed') {
            dispatch(showSnackBar({
                message: 'File upload failed',
                type: 'error',
            }));
        }
    }, [status, dispatch, boardId]);

    // Fetch Unsplash images
    useEffect(() => {
        if (page > 0) fetchUnsplashImages(page);
    }, [page]);

    const fetchUnsplashImages = async (page) => {
        if (!hasMoreImages) return;
        setIsLoadingUnsplash(true); // Show spinner

        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/unsplash?page=${page}&limit=30`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch images: ${response.statusText}`);
            }

            const data = await response.json();
            if (!data.success || !Array.isArray(data.photos)) {
                throw new Error('Unexpected response format from the server');
            }

            setUnsplashImages((prevImages) => [...prevImages, ...data.photos]);
            setHasMoreImages(data.photos.length > 0); // Disable loading when no more images
        } catch (error) {
            console.error('Error fetching Unsplash images:', error);
        } finally {
            setIsLoadingUnsplash(false); // Hide spinner
        }
    };

    const lastImageRef = useCallback((node) => {
        if (isLoadingUnsplash) return; // Avoid setting up the observer during loading
        if (observer.current) observer.current.disconnect(); // Disconnect previous observer

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMoreImages) {
                setPage((prevPage) => prevPage + 1); // Increment page when the last image is visible
            }
        });

        if (node) observer.current.observe(node); // Observe the last image node
    }, [isLoadingUnsplash, hasMoreImages]);

    const validateImageUrl = (url) => {
        const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
        return imageExtensions.test(url);
    };

    const debouncedHandleUnsplashImageSelect = useCallback(
        debounce(async (url) => {
            dispatch(showSnackBar({ message: 'Setting background...', type: 'uploading' }));

            try {
                const token = localStorage.getItem('accessToken');

                if (!token) {
                    throw new Error('User is not authenticated. Access token is missing.');
                }

                const response = await fetch(`${config.API_URI}/api/boards/boards-background`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ url, boardId }),
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Failed to set background: ${response.status} ${errorText}`);
                }

                // Refresh the board data after successful upload
                dispatch(fetchBoardById(boardId));
                dispatch(hideSnackBar());
            } catch (error) {
                console.error('Error setting background:', error);
                dispatch(showSnackBar({ message: 'Failed to set background', type: 'error' }));
            }
        }, 300), // Debounce with a 500ms delay
        [boardId, dispatch]
    );

    return (
        <div className="add-attachment">
            <div className="group">
                <h3 className='background-group-title'>or Choose an image from <a target='_blank' href='https://unsplash.com/?utm_source=zoobbe&utm_medium=referral&utm_campaign=api-credit'>Unsplash</a></h3>

                <div className="unsplash-background-images">
                    <div className="unsplash-images">
                        {unsplashImages.map((img, index) => {
                            const isLastImage = index === unsplashImages.length - 1;

                            return (
                                <div
                                    className="board-background-select"
                                    key={img.id}
                                    ref={isLastImage ? lastImageRef : null}
                                >
                                    <span
                                        className="background-box"
                                        data-testid={`board-background-select-photo-${index}`}
                                        style={{ backgroundImage: `url(${img.urls.thumb})` }}
                                        onClick={() => debouncedHandleUnsplashImageSelect(img.urls.regular)}
                                    >
                                        <div className="large">
                                            <a
                                                href={`${img.user.links.html}?utm_source=zoobbe&utm_medium=referral&utm_campaign=api-credit`}
                                                target="_blank"
                                                title={img.user.name}
                                            >
                                                {img.user.name}
                                            </a>
                                        </div>
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    {isLoadingUnsplash && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}>
                            <Spinner size={30} color="#3498db" speed={1.5} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Unsplash;
